<template>
   <div style="width:100%;height:100%;background-color:#F2EEEE;">
      <div style="width:100%;height:100%;background-color:#F2EEEE;"> 
         <v-container 
            style="max-width:2600px;"
            class="pa-0">
            
            <v-row style="" class="ma-0 pa-0 pa-1">
               <!-- List of users -->
               <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5"
                  class="pa-4"
                  style="border-right: 1px solid #CCCCCC;background-color:#656565;">
                  <div style="font-family:Lucida Sans Unicode;font-size: 18px;line-height:1.56;font-weight:700;color:white;border-bottom: 1px solid white;">
                     Danh sách nhân viên: {{users.length}}
                  </div>

                  <div class="py-2">
                     <v-card tile flat class="pa-0" style="background-color:transparent;">
                        <v-card-title class="pa-0">
                           <v-text-field
                              v-model="userSearchStr"
                              append-icon="mdi-magnify"
                              label="Search"
                              clearable
                              outlined
                              dense
                              single-line
                              hide-details
                              color="white"
                              dark
                              style="width:calc(100% - 120px);max-width:380px;"
                              ></v-text-field>
                              <v-spacer/>
                              <v-checkbox
                                 v-model="showAllUser"
                                 label="Tất cả"
                                 color="white"
                                 hide-details
                                 class="ma-0 pa-0"
                                 dark
                                 ></v-checkbox>
                        </v-card-title>
                     </v-card>
                  </div>

                  <div>
                     <v-data-table
                        :headers="usersHeader"
                        :items="renderUsers"
                        :page.sync="usersPage"
                        :items-per-page="15"
                        hide-default-footer dense
                        :search="userSearchStr"
                        class="elevation-1"
                        @page-count="pageCount = $event"
                        :mobile-breakpoint="0"
                     >
                        <template v-slot:item.actions="{ item }">
                           <v-btn v-if="!item.match"
                              icon color="#0288D1"
                              @click="pushUserToFloor(item)"
                              style="height:30px;width:30px;">
                              <v-icon style="font-size:18px;">
                                 mdi-arrow-right-bold
                              </v-icon>
                           </v-btn>
                        </template>
                        <template v-slot:item.fullname="{ item }">
                           <a :href="'/users/' + item._id">
                              {{ item.fullname }}
                           </a>
                        </template>
                     </v-data-table>
                     <div v-if="pageCount>1"
                        class="text-center pt-2">
                        <v-pagination
                           v-model="usersPage"
                           :length="pageCount"
                           ></v-pagination>
                     </div>
                  </div>
               </v-col>

               <!-- List of floors -->
               <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7"
                  class="pa-0">
                  <v-row style="" class="ma-0 pa-0 pa-1">
                     <v-col cols="12" class="px-1 py-1">
                        <div flat 
                           class="pa-0" 
                           style="border-bottom:2px solid #1976D2;">
                           <v-btn v-for="(floor, index) in floors" :key="index"
                              tile depressed
                              :text="activeFloor!=index"
                              :color="activeFloor==index?'primary':'#AAAAAA'"
                              @click="activeFloor=index"
                              class="text-none px-2">
                              <span style="font-size:13px;font-weight:700;">
                                 {{floor.name}}
                              </span>
                           </v-btn>
                        </div>
                     </v-col>
                  </v-row>
                  <v-row v-if="floors"
                     class="px-6 pa-0">
                     <!--
                     <v-col 
                        v-for="(door, index) in floors[activeFloor].doors" :key="index"
                        cols="6"
                        class="pa-0 pt-1 pr-2">
                        <door-users 
                           @user-delete-done="onDeleteUserDone"
                           :users="door.users" 
                           :floor="floors[activeFloor]" />
                     </v-col>
                     -->
                     <v-col 
                        cols="12"
                        class="pa-0 pt-1 pr-2">
                        <door-users 
                           @user-delete-done="onDeleteUserDone"
                           :users="floors[activeFloor].doors[0].users" 
                           :floor="floors[activeFloor]" />
                     </v-col>
                  </v-row>
               </v-col>
            </v-row>
         </v-container>
      </div>
   </div>
</template>

<script>
export default {
   watch: {
      activeFloor: function () {
         this.fetchUserForFloor(this.floors[this.activeFloor])
      },
      showAllUser: function () {
         this.calulateRenderUser()
      }
   },
   data () {
      return {
         roles: ['access-manager', 'admin'],
         userSearchStr: '',
         users:[],
         renderUsers: [],
         showAllUser: false,
         pageCount: 0,
         usersPage: 1,
         usersHeader: [
            {
               text: 'Mã NV',
               align: 'start',
               sortable: false,
               value: 'mcvId',
               // value: 'user_code',
               width: '70px',
            },
            { text: 'Họ và Tên', value: 'fullname' },
            { text: '', value: 'actions' },
         ],
         departments: [],
         socketAskKey: new Date().getTime(),
         activeFloor: 0,
         floors: require('@/mkdata/floors').floors

      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         console.log("callHTTPRequestResult")
         if(payload && payload.askKey == this.socketAskKey) {
            if(payload.result && payload.result.status=="OK"){
               if(payload.result.content.api == "getAllUser"){
                  let ip = payload.result.content.ip
                  let users = payload.result.content.users
                  users = users.sort(function(a, b) {
                     return a.userId.localeCompare(b.userId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                     });
                  });

                  this.users.forEach( u => u.match = false)

                  users.forEach(u => {
                     if(this.users && this.users.length>0){
                        let matchUser = this.users.find(sysUser => sysUser.mcvId == u.userId)
                        if(matchUser){
                           matchUser.match = true
                           u.name = matchUser.fullname
                           u.match = true
                           u.matchS = ""
                        } else {
                           u.match = false
                           u.matchS = "xxx"
                           // console.log(u)
                        }
                     }
                  })

                  this.floors.forEach(floor => {
                     floor.doors.forEach(door => {
                        if(door.ip == ip) {
                           door.users = users}
                     })
                  })

               }
               this.calulateRenderUser()
               this.$forceUpdate()
               // this.blocks = this.blocks.concat(payload.result.content)
               // this.processDataBlocksAfterFetch()
            } else {
               console.log(payload.result)
            }
         }
      },
      'readAllUserLite-REPLY': function(payload){
         if(payload && payload.askKey == this.socketAskKey){
            // payload.result.users.forEach(u => console.log(u))
            /*
               name: "Lê Như Huỳnh"
               cardNumber: null
               id: "T65"
               password: ""
               enabled: true
               role: 0
            */
            let devUsers = payload.result.users
            let ip = payload.result.ip
            devUsers = devUsers.sort(function(a, b) {
               return a.id.localeCompare(b.id, undefined, {
                  numeric: true,
                  sensitivity: 'base'
               });
            });

            this.users.forEach( u => u.match = false)

            devUsers.forEach(u => {
               u.userId = u.id
               if(this.users && this.users.length>0){
                  let matchUser = this.users.find(sysUser => sysUser.mcvId == u.id)
                  if(matchUser){
                     matchUser.match = true
                     u.name = matchUser.fullname
                     u._id = matchUser._id
                     u.match = true
                     u.matchS = ""
                  } else {
                     u.match = false
                     u.matchS = "xxx"
                     // console.log(u)
                  }
               }
            })

            this.floors.forEach(floor => {
               floor.doors.forEach(door => {
                  if(door.ip == ip) {
                     door.users = devUsers}
               })
            })


            let self = this
            self.showAllUser = true
            setTimeout(function(){
               self.showAllUser = false
            }, 200)
            this.$forceUpdate()
         }
      },
      'setUserInfo-REPLY': function (payload) {
         this.loading = false
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            console.log('setUserInfo-REPLY')
            console.log(this.result)
            this.fetchUserForFloor(this.floors[this.activeFloor])
         }
      }
   },
   methods: {
      calulateRenderUser(){
         if(this.showAllUser) this.renderUsers = this.users
         else this.renderUsers = this.users.filter(u => !u.match)
      },
      pushUserToFloor(user){
         if(confirm("Cho phép " + user.fullname + " vào " + this.floors[this.activeFloor].name + "?")){
            this.addUserToFloor(user)
         }
         
      },
      convertMaNVToCompanyId(code){
         if(code && code.length>0){
            let sCode = code.replace("NV", "")
            if(sCode.includes('T')) return sCode
            else  return Number(sCode)
         }
         return -1
      },
      fetchUsers() {
         return new Promise((resolve) => {
            let self = this;
            let pathFetchDataUsers =
            self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId;
            let pathFetchDataDepartments =
            self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId;

            self.loading = true;
            Promise.all([
            self.axios.get(pathFetchDataUsers),
            self.axios.get(pathFetchDataDepartments),
            ])
            .then((responseArray) => {
               // console.log("Promise ALL",responseArray );
               if (responseArray[0].data.status == "OK") {
                  self.users = responseArray[0].data.content.items;
                  self.users.forEach(u => {
                     u.mcvId = this.convertMaNVToCompanyId(u.user_code)
                  })

                  self.users = self.users.sort(function(a, b) {
                     return Number(a.mcvId) - Number(b.mcvId)
                  });
                  // console.log(self.users)
               }
               if (responseArray[1].data.status == "OK") {
                  self.departments = responseArray[1].data.content.items;
                  // console.log(self.departments)
               }
               this.$forceUpdate()
            })
            .catch((err) => {
               console.log(err);
            })
            .finally(() => {
               // self.processTotalReport();
               self.loading = false;
               resolve()
            });
         })
      },
      fetchUserForFloor (floor) {
         /* call via socket */
         this.$socket.emit('sendMagicCommand', 
         {
            command: "readAllUserLite",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + floor.doors[0].ip,
            paras: {}
         })


         /*
         call over API

         floor.doors.forEach((door, index) => {
            let self = this
            setTimeout(function(){
               self.callApiOverOTA(
                  "POST",
                  self.$root.apiZK + "zk/getAllUser",
                  {  ip: door.ip}
               )
            }, 100*index)
         })
         */
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
      addUserToFloor(user){
         this.floors[this.activeFloor].ips.forEach(ip => {
            if(!ip|| !user) return

            let userCode = user.user_code.replace("NV", "")
            if(!userCode.includes('T')){
              userCode = ""+Number(userCode)
            }

            let paras = {
              userId: userCode,
              name: user.fullname,
              password: '',
              card_no: '',
              face: '',
              role: 0,
              faceLength: 0,
              fingers: ['','','','','','','','','','']
            }

            if(user.zk_access){
              paras.password = user.zk_access.password
              paras.card_no = user.zk_access.card_no
               if(user.zk_access.role && user.zk_access.role.length>0) {
                  paras.role = Number(user.zk_access.role)
               }
              if(user.zk_access.faces && user.zk_access.faces.length>0){
                paras.face = user.zk_access.faces[0].template
                paras.faceLength = user.zk_access.faces[0].fLength
              }

              if(user.zk_access.fingers && user.zk_access.fingers.length>0){
                user.zk_access.fingers.forEach((finger) => {
                  console.log(finger)
                  paras.fingers[finger.index] = finger.template
                })
              }

            }

            // console.log(paras)

            this.result = "..."
            this.loading = true
            this.sendTime = new Date()
            this.$socket.emit('sendMagicCommand', 
            {
               command: "setUserInfo",
               askKey: this.socketAskKey,
               devCode: "MCVZK-" + ip,
               paras: paras
            })
         }) 
      },
      onDeleteUserDone () {
         this.fetchUserForFloor(this.floors[this.activeFloor])
      }
   },
   mounted () {
      this.$nextTick(() => {
         this.fetchUsers().then(() => {
            let self = this
            setTimeout(function(){
               self.fetchUserForFloor(self.floors[0])
            }, 100)
         })
      })
   }
}
</script>

<style lang="">
   
</style>